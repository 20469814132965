import React from 'react';
import Spinner from '../components/spinner'; // Adjust the path as necessary
import './twitchgen.css'; // Make sure to uncomment to apply your styles

function TwitchGen() {
  return (
    <div className="TwitchGen">
      <Spinner />
    </div>
  );
}

export default TwitchGen;