import React, { useEffect, useState } from 'react';
import { message, Button, Table, Input, Select, DatePicker, Form, Row, Col } from 'antd';
import axios from 'axios';
import './servermessages.css';
import moment from 'moment';
import { Tooltip } from 'antd';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const { Option } = Select;

const ServerMessages = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

const fetchData = async () => {
    try {
      const response = await axios.get('https://api.cs2inspects.com/gameservermessage/list', {
        params: { key: 'something123' }
      });
      console.log("API Response:", response.data); // Log the raw API response

      const formattedData = response.data.map(item => {
        const formattedItem = {
          ...item,
          startDate: moment(item.startDate), 
          endDate: moment(item.endDate) // Convert endDate string to moment object
        };
        console.log("Formatted item:", formattedItem); // Log each formatted item
        return formattedItem;
      });

      setData(formattedData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      message.error('Failed to fetch data: ' + error.message);
    }
  };

  const handleDelete = async (record) => {
    const originalData = [...data];
    setData(data.filter(item => item._id !== record._id));  // Optimistically remove the item
  
    try {
      const response = await axios.post('https://api.cs2inspects.com/gameservermessage/remove', {
        _id: record._id,
      }, {
        params: { key: 'something123' }
      });
  
      if (!response.data.success) {
        setData(originalData);  // Revert if the deletion fails
        message.error('Failed to deactivate message: ' + response.data.message);
      } else {
        message.success('Message deactivated successfully');
      }
    } catch (error) {
      setData(originalData);  // Revert if there's an error
      message.error('Failed to delete message: ' + (error.response && error.response.data.error ? error.response.data.error : 'Unknown error'));
    }
  };

  const handleUpdate = async (record) => {
    try {
      const newDocument = {
        ...record,
        startDate: record.startDate.toISOString(), // Convert moment object to ISO string
        endDate: record.endDate.toISOString(), 
        isactive: true,  // Ensure the new document is active
        status: [], // Initialize as empty or with some default statuses
        isactive: true, // Ensure the new document is active
      };
  
      // Remove the _id from the new document to ensure MongoDB generates a new one
      delete newDocument._id;
  
      const payload = {
        _id: record._id,
        newDocument: newDocument
      };
  
      // API call to update the document
      const response = await axios.post('https://api.cs2inspects.com/gameservermessage/update', payload, {
        params: { key: 'something123' }
      });
  
      fetchData();  // Refresh data after update
      message.success('Update successful for item ' + record._id);
      console.log("Update response:", response.data);
    } catch (error) {
      console.error('Failed to update message:', error.response ? error.response.data : error);
      message.error('Failed to update message: ' + (error.response && error.response.data.error ? error.response.data.error : 'Unknown error'));
    }
  };


const handleSubmit = async (values) => {
  console.log("Form values on submit:", values);

  // Convert date objects to ISO strings if not already in ISO format
  const startDate = values.startDate ? (values.startDate instanceof Date ? values.startDate.toISOString() : values.startDate) : null;
  const endDate = values.endDate ? (values.endDate instanceof Date ? values.endDate.toISOString() : values.endDate) : null;

  const payload = {
    ...values,
    startDate,
    endDate,
    status: [],
    isactive: true,
  };

  console.log("Payload to send:", payload);

  try {
    const response = await axios.post('https://api.cs2inspects.com/gameservermessage/add', payload, {
      params: { key: 'something123' }
    });
    fetchData();
    form.resetFields();
    message.success('Message added successfully');
  } catch (error) {
    console.error('Failed to add message:', error);
    message.error('Failed to add message: ' + (error.response && error.response.data.error ? error.response.data.error : 'Unknown error'));
  }
};


const handleDateChange = (date, record, type) => {
  const updatedData = data.map(item => {
    if (item._id === record._id) {
      return {...item, [type]: date};
    }
    return item;
  });
  setData(updatedData);
};

const handleDuplicate = (record) => {
  form.setFieldsValue({
    type: record.type,
    frequencyMinutes: record.frequencyMinutes,
    startDate: record.startDate ? new Date(record.startDate) : null,
    endDate: record.endDate ? new Date(record.endDate) : null,
    content: record.content,
    targetServers: record.targetServers
  });
  setStartDate(record.startDate ? new Date(record.startDate) : null);
  setEndDate(record.endDate ? new Date(record.endDate) : null);
};

const columns = [
    {
      title: 'Frequency',
      dataIndex: 'type',
      key: 'type',
      width: 100, 
      render: (text, record) => (
        <Select defaultValue={text} style={{ width: '100%' }} onChange={value => record.frequency = value}>
          <Option value="onetime">onetime</Option>
          <Option value="recurring">recurring</Option>
        </Select>
      ),
    },
    {
      title: 'Minutes',
      dataIndex: 'frequencyMinutes',
      key: 'frequencyMinutes',
      width: 100, 
      render: (text, record) => <Input defaultValue={text} onChange={e => record.minutes = e.target.value} />,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 400, 
      render: (text, record) => (
        <ReactDatePicker
          style={{ backgroundColor: 'white', color: 'black' }}
          selected={record.startDate ? new Date(record.startDate) : null}
          onChange={(date) => handleDateChange(date, record, 'startDate')}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
          className="form-control"
        />
      )
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 400, 
      render: (text, record) => (
        <ReactDatePicker
          style={{ backgroundColor: 'white', color: 'black' }}
          selected={record.endDate ? new Date(record.endDate) : null}
          onChange={(date) => handleDateChange(date, record, 'endDate')}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
          className="form-control"
        />
      )
    },

    {
      title: 'Message',
      dataIndex: 'content',
      key: 'content',
      render: (text, record) => <Input defaultValue={text} onChange={e => record.message = e.target.value} />,
    },
    {
      title: 'Servers',
      dataIndex: 'targetServers',
      key: 'targetServers',
      width: 300, 
      render: (text, record) => (
        <Select mode="multiple" defaultValue={text} onChange={values => record.targetServers = values} placeholder="Select servers">
            <Option value="NA1">NA1</Option>
            <Option value="NA2">NA2</Option>
            <Option value="NA3">NA3</Option>
            <Option value="EU1">EU1</Option>
            <Option value="EU2">EU2</Option>
            <Option value="EU3">EU3</Option>
            <Option value="AS1">AS1</Option>
            <Option value="Private">Private</Option>
            <Option value="Trade">Trade</Option>
            <Option value="Arrow">Arrow</Option>
            <Option value="Anomaly">Anomaly</Option>
            <Option value="Ohne">Ohne</Option>
            <Option value="Staxx">Staxx</Option>
          {/* <Option value="all">all</Option>
          <Option value="NA1">NA1</Option>
          <Option value="NA2">NA2</Option>
          <Option value="NA3">NA3</Option>
          <Option value="EU1">EU1</Option>
          <Option value="EU2">EU2</Option>
          <Option value="EU2">EU3</Option>
          <Option value="AS1">AS1</Option>
          <Option value="Private">Private</Option>
          <Option value="Trade">Trade</Option>
          <Option value="1.2.3.4">1.2.3.4</Option>
            <Option value="131.153.147.234">131.153.147.234</Option>
            <Option value="131.153.147.235">131.153.147.235</Option>
            <Option value="131.153.147.236">131.153.147.236</Option>
            <Option value="131.153.147.237">131.153.147.237</Option>
            <Option value="131.153.147.238">131.153.147.238</Option>
            <Option value="131.153.146.62">131.153.146.62</Option>
            <Option value="131.153.146.59">131.153.146.59</Option>
            <Option value="131.153.146.60">131.153.146.60</Option>
            <Option value="131.153.146.61">131.153.146.61</Option>
            <Option value="131.153.150.226">131.153.150.226</Option>
            <Option value="131.153.150.227">131.153.150.227</Option>
            <Option value="131.153.150.228">131.153.150.228</Option>
            <Option value="131.153.150.229">131.153.150.229</Option>
            <Option value="131.153.150.230">131.153.150.230</Option>
            <Option value="131.153.150.107">131.153.150.107</Option>
            <Option value="131.153.150.108">131.153.150.108</Option>
            <Option value="131.153.150.109">131.153.150.109</Option>
            <Option value="131.153.150.110">131.153.150.110</Option>
            <Option value="131.153.151.2">131.153.151.2</Option>
            <Option value="131.153.151.3">131.153.151.3</Option>
            <Option value="131.153.151.4">131.153.151.4</Option>
            <Option value="131.153.151.5">131.153.151.5</Option>
            <Option value="131.153.151.6">131.153.151.6</Option>
            <Option value="131.153.150.115">131.153.150.115</Option>
            <Option value="131.153.150.116">131.153.150.116</Option>
            <Option value="131.153.150.117">131.153.150.117</Option>
            <Option value="131.153.150.118">131.153.150.118</Option>
            <Option value="185.52.53.202">185.52.53.202</Option>
            <Option value="185.52.53.203">185.52.53.203</Option>
            <Option value="185.52.53.204">185.52.53.204</Option>
            <Option value="185.52.53.205">185.52.53.205</Option>
            <Option value="185.52.53.206">185.52.53.206</Option>
            <Option value="185.52.53.124">185.52.53.124</Option>
            <Option value="185.52.53.123">185.52.53.123</Option>
            <Option value="185.52.53.125">185.52.53.125</Option>
            <Option value="185.52.53.126">185.52.53.126</Option>
            <Option value="185.52.54.186">185.52.54.186</Option>
            <Option value="185.52.54.187">185.52.54.187</Option>
            <Option value="185.52.54.188">185.52.54.188</Option>
            <Option value="185.52.54.189">185.52.54.189</Option>
            <Option value="185.52.54.190">185.52.54.190</Option>
            <Option value="185.52.53.108">185.52.53.108</Option>
            <Option value="185.52.53.107">185.52.53.107</Option>
            <Option value="185.52.53.109">185.52.53.109</Option>
            <Option value="185.52.53.110">185.52.53.110</Option>

            <Option value="131.153.46.203">131.153.46.203</Option>
            <Option value="131.153.46.204">131.153.46.204</Option>
            <Option value="131.153.46.205">131.153.46.205</Option>
            <Option value="131.153.46.206">131.153.46.206</Option>
            <Option value="131.153.47.210">131.153.47.210</Option>
            <Option value="131.153.47.211">131.153.47.211</Option>
            <Option value="131.153.47.212">131.153.47.212</Option>


            <Option value="185.56.138.179">185.56.138.179</Option>
            <Option value="185.56.138.180">185.56.138.180</Option>
            <Option value="185.56.138.181">185.56.138.181</Option>
            <Option value="185.56.138.182">185.56.138.182</Option>
            <Option value="185.56.138.242">185.56.138.242</Option>
            <Option value="185.56.138.243">185.56.138.243</Option>
            <Option value="185.56.138.244">185.56.138.244</Option>
            <Option value="185.56.138.245">185.56.138.245</Option>
            <Option value="185.56.138.246">185.56.138.246</Option> */}
        </Select>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: 200, 
      render: (_, record) => (
        <Tooltip
          title={record.status.map(s => `Server IP: ${s.serverIP}, Picked Up: ${moment(s.pickedUpDate).format('YYYY-MM-DD HH:mm')}`).join('\n')}
          placement="top"
        >
          <Button>View Status</Button>
        </Tooltip>
      )
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   width: 200, 
    //   render: (_, record) => (
    //     <>
    //       <Button onClick={() => handleUpdate(record)}>Update</Button>
    //       <Button type="danger" onClick={() => handleDelete(record)} style={{ marginLeft: '10px' }}>Delete</Button>
    //     </>
    //   )
    // }
    {
      title: 'Actions',
      key: 'actions',
      width: 200, 
      render: (_, record) => (
        <>
          <Button onClick={() => handleUpdate(record)}>Update</Button>
          <Button onClick={() => handleDuplicate(record)} style={{ marginLeft: '10px' }}>Duplicate</Button>
          <Button type="danger" onClick={() => handleDelete(record)} style={{ marginLeft: '10px' }}>Delete</Button>
        </>
      )
    }
  ];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Whenever the local startDate changes, update the form field
    form.setFieldsValue({startDate: startDate});
  }, [startDate, form]);

  useEffect(() => {
    // Whenever the local endDate changes, update the form field
    form.setFieldsValue({endDate: endDate});
  }, [endDate, form]);
  

  return (
    <div className="server-messages">
    <h1>Server Messages</h1>
    <Table dataSource={data} columns={columns} pagination={false} style={{ marginBottom: '20px' }} />
        <Form form={form} onFinish={handleSubmit} layout="vertical" className="server-messages-form">
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="type" label="Frequency">
              <Select>
                <Option value="onetime">One time</Option>
                <Option value="recurring">Recurring</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="frequencyMinutes" label="Minutes">
              <Input type="number" />
            </Form.Item>
          </Col>

<Col span={6}>
<Form.Item
  name="startDate"
  label="Start Date"
>
<ReactDatePicker
  selected={startDate}
  onChange={date => {
    console.log("Updating Date:", date);
    setStartDate(date); // Update local state
  }}
  showTimeSelect
  dateFormat="MMMM d, yyyy h:mm aa"
  wrapperClassName="datePicker"
/>

</Form.Item>
</Col>
<Col span={6}>
<Form.Item name="endDate" label="End Date">
  <ReactDatePicker
    selected={endDate}
    onChange={date => {
      console.log("Updating End Date:", date);
      setEndDate(date); // Update local state
    }}
    showTimeSelect
    dateFormat="MMMM d, yyyy h:mm aa"
    wrapperClassName="datePicker"
  />
</Form.Item>
</Col>
        </Row>
        <Form.Item name="content" label="Message">
          <Input />
        </Form.Item>
        <Form.Item name="targetServers" label="Servers">
          <Select mode="multiple" placeholder="Select servers">
            <Option value="NA1">NA1</Option>
            <Option value="NA2">NA2</Option>
            <Option value="NA3">NA3</Option>
            <Option value="EU1">EU1</Option>
            <Option value="EU2">EU2</Option>
            <Option value="EU3">EU3</Option>
            <Option value="AS1">AS1</Option>
            <Option value="Private">Private</Option>
            <Option value="Trade">Trade</Option>
            <Option value="Arrow">Arrow</Option>
            <Option value="Anomaly">Anomaly</Option>
            <Option value="Ohne">Ohne</Option>
            <Option value="Staxx">Staxx</Option>
          {/* <Option value="all">all</Option>
          <Option value="NA1">NA1</Option>
          <Option value="NA2">NA2</Option>
          <Option value="NA3">NA3</Option>
          <Option value="EU1">EU1</Option>
          <Option value="EU2">EU2</Option>
          <Option value="EU2">EU3</Option>
          <Option value="AS1">AS1</Option>
          <Option value="Private">Private</Option>
          <Option value="Trade">Trade</Option>
          <Option value="1.2.3.4">1.2.3.4</Option>
            <Option value="131.153.147.234">131.153.147.234</Option>
            <Option value="131.153.147.235">131.153.147.235</Option>
            <Option value="131.153.147.236">131.153.147.236</Option>
            <Option value="131.153.147.237">131.153.147.237</Option>
            <Option value="131.153.147.238">131.153.147.238</Option>
            <Option value="131.153.146.62">131.153.146.62</Option>
            <Option value="131.153.146.59">131.153.146.59</Option>
            <Option value="131.153.146.60">131.153.146.60</Option>
            <Option value="131.153.146.61">131.153.146.61</Option>
            <Option value="131.153.150.226">131.153.150.226</Option>
            <Option value="131.153.150.227">131.153.150.227</Option>
            <Option value="131.153.150.228">131.153.150.228</Option>
            <Option value="131.153.150.229">131.153.150.229</Option>
            <Option value="131.153.150.230">131.153.150.230</Option>
            <Option value="131.153.150.107">131.153.150.107</Option>
            <Option value="131.153.150.108">131.153.150.108</Option>
            <Option value="131.153.150.109">131.153.150.109</Option>
            <Option value="131.153.150.110">131.153.150.110</Option>
            <Option value="131.153.151.2">131.153.151.2</Option>
            <Option value="131.153.151.3">131.153.151.3</Option>
            <Option value="131.153.151.4">131.153.151.4</Option>
            <Option value="131.153.151.5">131.153.151.5</Option>
            <Option value="131.153.151.6">131.153.151.6</Option>
            <Option value="131.153.150.115">131.153.150.115</Option>
            <Option value="131.153.150.116">131.153.150.116</Option>
            <Option value="131.153.150.117">131.153.150.117</Option>
            <Option value="131.153.150.118">131.153.150.118</Option>
            <Option value="185.52.53.202">185.52.53.202</Option>
            <Option value="185.52.53.203">185.52.53.203</Option>
            <Option value="185.52.53.204">185.52.53.204</Option>
            <Option value="185.52.53.205">185.52.53.205</Option>
            <Option value="185.52.53.206">185.52.53.206</Option>
            <Option value="185.52.53.124">185.52.53.124</Option>
            <Option value="185.52.53.123">185.52.53.123</Option>
            <Option value="185.52.53.125">185.52.53.125</Option>
            <Option value="185.52.53.126">185.52.53.126</Option>
            <Option value="185.52.54.186">185.52.54.186</Option>
            <Option value="185.52.54.187">185.52.54.187</Option>
            <Option value="185.52.54.188">185.52.54.188</Option>
            <Option value="185.52.54.189">185.52.54.189</Option>
            <Option value="185.52.54.190">185.52.54.190</Option>
            <Option value="185.52.53.108">185.52.53.108</Option>
            <Option value="185.52.53.107">185.52.53.107</Option>
            <Option value="185.52.53.109">185.52.53.109</Option>
            <Option value="185.52.53.110">185.52.53.110</Option>
            <Option value="131.153.46.203">131.153.46.203</Option>
            <Option value="131.153.46.204">131.153.46.204</Option>
            <Option value="131.153.46.205">131.153.46.205</Option>
            <Option value="131.153.46.206">131.153.46.206</Option>
            <Option value="131.153.47.210">131.153.47.210</Option>
            <Option value="131.153.47.211">131.153.47.211</Option>
            <Option value="131.153.47.212">131.153.47.212</Option>
            <Option value="185.56.138.179">185.56.138.179</Option>
            <Option value="185.56.138.180">185.56.138.180</Option>
            <Option value="185.56.138.181">185.56.138.181</Option>
            <Option value="185.56.138.182">185.56.138.182</Option>
            <Option value="185.56.138.242">185.56.138.242</Option>
            <Option value="185.56.138.243">185.56.138.243</Option>
            <Option value="185.56.138.244">185.56.138.244</Option>
            <Option value="185.56.138.245">185.56.138.245</Option>
            <Option value="185.56.138.246">185.56.138.246</Option> */}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Message</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ServerMessages;
