import React, { useState, useEffect } from 'react';
import { Button, Input, Table, Form, InputNumber, Radio, message, Select, Tag } from 'antd';
//import './servermessagestwitch.css'; // Make sure to uncomment to apply your styles
import axios from 'axios';

const { Option } = Select;

const ServerMessagesTwitch = () => {
  const [usernames, setUsernames] = useState([]);
  const [messageType, setMessageType] = useState(null); // Add a state to keep track of message type
  const [selectedServers, setSelectedServers] = useState([]); // State to store selected servers
  const [form] = Form.useForm();

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get('https://api.cs2inspects.com/gameservermessagetwitch/list', { params: { key: 'something123' } });
      console.log(response.data);
      setUsernames(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSubmit = async (values) => {
    const replaceUsername = (message, username) => {
      return message.replace(/\[TWITCHUSER\]/g, username);
    };

    const finalMessage = messageType === 'custom' 
      ? replaceUsername(values.customMessage, values.username) 
      : replaceUsername(messageType, values.username);

    const newEntry = {
      username: values.username,
      frequency: values.frequency,
      message: finalMessage,
      servers: selectedServers, // Include selected servers in the entry
    };
    
    try {
      await axios.post('https://api.cs2inspects.com/gameservermessagetwitch/add', newEntry, { params: { key: 'something123' } });
      fetchMessages();
      form.resetFields(); // Reset fields but maintain the message type state
      setMessageType(null); // Optionally reset the messageType if you want the form to be fully cleared
      setSelectedServers([]); // Clear selected servers
      message.success('Username added successfully');
    } catch (error) {
      console.error('Error adding username:', error);
    }
  };

  const handleDelete = async (username) => {
    try {
      await axios.post('https://api.cs2inspects.com/gameservermessagetwitch/remove', { _id: username }, { params: { key: 'something123' } });
      fetchMessages();
      message.success('Username removed successfully');
    } catch (error) {
      console.error('Error removing username:', error);
    }
  };

  const handleMessageChange = e => {
    setMessageType(e.target.value); // Update message type state on change
    form.setFieldsValue({ messageType: e.target.value }); // Update form field to ensure it matches state
  };

  const handleServerChange = (selected) => {
    setSelectedServers(selected); // Update selected servers
  };

  const columns = [
    {
      title: 'Twitch Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Frequency (minutes)',
      dataIndex: 'frequency',
      key: 'frequency',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      ellipsis: true, // Allow text wrapping
      width: 'auto' // Adjust width as needed
    },
    {
      title: 'Servers',
      dataIndex: 'servers',
      key: 'servers',
      render: (servers) => (
        <span>
          {servers.map(server => (
            <Tag color="blue" key={server}>{server}</Tag>
          ))}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button onClick={() => handleDelete(record._id)} type="danger">
          Delete
        </Button>
      ),
    }
  ];

  return (
    <div className="server-messages-twitch">
      <h1>Manage Twitch Usernames</h1>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="username" label="Twitch User:" rules={[{ required: true, message: 'Please input a Twitch username!' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="frequency" label="Frequency:" rules={[{ required: true, message: 'Please specify the frequency!' }]}>
          <InputNumber min={1} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="messageType" label="Message:">
          <Radio.Group onChange={handleMessageChange} value={messageType}>
            <Radio value="[TWITCHUSER] is currently inspecting skins while streaming on twitch.tv/[TWITCHUSER]">[TWITCHUSER] is currently inspecting skins while streaming on twitch.tv/[TWITCHUSER]</Radio>
            <Radio value="[TWITCHUSER] checking out the latest crafts live on twitch.tv/[TWITCHUSER]">[TWITCHUSER] checking out the latest crafts live on twitch.tv/[TWITCHUSER]</Radio>
            <Radio value="custom">Custom Message</Radio>
          </Radio.Group>
        </Form.Item>
        {messageType === 'custom' && (
          <Form.Item name="customMessage" rules={[{ required: true, message: 'Please enter a custom message!' }]}>
            <Input />
          </Form.Item>
        )}
        <Form.Item name="targetServers" label="Servers">
          <Select
            mode="multiple"
            placeholder="Select servers"
            onChange={handleServerChange}
            value={selectedServers}
          >
            {/* Options for servers */}
            <Option value="NA1">NA1</Option>
            <Option value="NA2">NA2</Option>
            <Option value="NA3">NA3</Option>
            <Option value="EU1">EU1</Option>
            <Option value="EU2">EU2</Option>
            <Option value="EU3">EU3</Option>
            <Option value="AS1">AS1</Option>
            <Option value="Private">Private</Option>
            <Option value="Trade">Trade</Option>
            <Option value="Arrow">Arrow</Option>
            <Option value="Anomaly">Anomaly</Option>
            <Option value="Ohne">Ohne</Option>
            <Option value="Staxx">Staxx</Option>
            {/* <Option value="all">all</Option>
            <Option value="NA1">NA1</Option>
            <Option value="NA2">NA2</Option>
            <Option value="NA3">NA3</Option>
            <Option value="EU1">EU1</Option>
            <Option value="EU2">EU2</Option>
            <Option value="EU2">EU3</Option>
            <Option value="AS1">AS1</Option>
            <Option value="Private">Private</Option>
            <Option value="Trade">Trade</Option>
            <Option value="1.2.3.4">1.2.3.4</Option>
            <Option value="131.153.147.234">131.153.147.234</Option>
            <Option value="131.153.147.235">131.153.147.235</Option>
            <Option value="131.153.147.236">131.153.147.236</Option>
            <Option value="131.153.147.237">131.153.147.237</Option>
            <Option value="131.153.147.238">131.153.147.238</Option>
            <Option value="131.153.146.62">131.153.146.62</Option>
            <Option value="131.153.146.59">131.153.146.59</Option>
            <Option value="131.153.146.60">131.153.146.60</Option>
            <Option value="131.153.146.61">131.153.146.61</Option>
            <Option value="131.153.150.226">131.153.150.226</Option>
            <Option value="131.153.150.227">131.153.150.227</Option>
            <Option value="131.153.150.228">131.153.150.228</Option>
            <Option value="131.153.150.229">131.153.150.229</Option>
            <Option value="131.153.150.230">131.153.150.230</Option>
            <Option value="131.153.150.107">131.153.150.107</Option>
            <Option value="131.153.150.108">131.153.150.108</Option>
            <Option value="131.153.150.109">131.153.150.109</Option>
            <Option value="131.153.150.110">131.153.150.110</Option>
            <Option value="131.153.151.2">131.153.151.2</Option>
            <Option value="131.153.151.3">131.153.151.3</Option>
            <Option value="131.153.151.4">131.153.151.4</Option>
            <Option value="131.153.151.5">131.153.151.5</Option>
            <Option value="131.153.151.6">131.153.151.6</Option>
            <Option value="131.153.150.115">131.153.150.115</Option>
            <Option value="131.153.150.116">131.153.150.116</Option>
            <Option value="131.153.150.117">131.153.150.117</Option>
            <Option value="131.153.150.118">131.153.150.118</Option>
            <Option value="185.52.53.202">185.52.53.202</Option>
            <Option value="185.52.53.203">185.52.53.203</Option>
            <Option value="185.52.53.204">185.52.53.204</Option>
            <Option value="185.52.53.205">185.52.53.205</Option>
            <Option value="185.52.53.206">185.52.53.206</Option>
            <Option value="185.52.53.124">185.52.53.124</Option>
            <Option value="185.52.53.123">185.52.53.123</Option>
            <Option value="185.52.53.125">185.52.53.125</Option>
            <Option value="185.52.53.126">185.52.53.126</Option>
            <Option value="185.52.54.186">185.52.54.186</Option>
            <Option value="185.52.54.187">185.52.54.187</Option>
            <Option value="185.52.54.188">185.52.54.188</Option>
            <Option value="185.52.54.189">185.52.54.189</Option>
            <Option value="185.52.54.190">185.52.54.190</Option>
            <Option value="185.52.53.108">185.52.53.108</Option>
            <Option value="185.52.53.107">185.52.53.107</Option>
            <Option value="185.52.53.109">185.52.53.109</Option>
            <Option value="185.52.53.110">185.52.53.110</Option>
            <Option value="131.153.46.203">131.153.46.203</Option>
            <Option value="131.153.46.204">131.153.46.204</Option>
            <Option value="131.153.46.205">131.153.46.205</Option>
            <Option value="131.153.46.206">131.153.46.206</Option>
            <Option value="131.153.47.210">131.153.47.210</Option>
            <Option value="131.153.47.211">131.153.47.211</Option>
            <Option value="131.153.47.212">131.153.47.212</Option>
            <Option value="185.56.138.179">185.56.138.179</Option>
            <Option value="185.56.138.180">185.56.138.180</Option>
            <Option value="185.56.138.181">185.56.138.181</Option>
            <Option value="185.56.138.182">185.56.138.182</Option>
            <Option value="185.56.138.242">185.56.138.242</Option>
            <Option value="185.56.138.243">185.56.138.243</Option>
            <Option value="185.56.138.244">185.56.138.244</Option>
            <Option value="185.56.138.245">185.56.138.245</Option>
            <Option value="185.56.138.246">185.56.138.246</Option> */}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Username</Button>
        </Form.Item>
      </Form>
      <Table dataSource={usernames} columns={columns} rowKey="_id" />
    </div>
  );
};

export default ServerMessagesTwitch;
