import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Input, Form, Button, message } from 'antd';
import ServerMessages from './pages/servermessages.js'; 
import ServerMessagesTwitch from './pages/servermessagestwitch.js';
import TwitchGen from './pages/twitchgen'; 

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, path) {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
  };
}

const items = [
  getItem('Stats', '1', <PieChartOutlined />, null, '/'),
  getItem('Servers', '2', <DesktopOutlined />, null, '/'),
  // getItem('Hmm', 'sub1', <UserOutlined />, [
  //   getItem('555', '3', null, null, '/'),
  //   getItem('666', '4', null, null, '/'),
  //   getItem('777', '5', null, null, '/'),
  // ]),
  getItem('Features', 'sub2', <TeamOutlined />, [
    getItem('Server Messages', '6', null, null, '/server-messages'),
    getItem('Server Messages (Twitch)', '67', null, null, '/server-messages-twitch'),
    getItem('Discord Roles', '8', null, null, '/discord-roles'),
    getItem('TwitchGen', '18', null, null, '/twitch-gen'),
  ]),
  // getItem('Dunno', '9', <FileOutlined />, null, '/'),
];

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onFinish = (values) => {
    if (values.password === "yourpassword") {
      setIsLoggedIn(true);
    } else {
      message.error('Incorrect password');
    }
  };

  if (!isLoggedIn) {
    return (
      <div style={{ maxWidth: 300, margin: '100px auto' }}>
        <Form onFinish={onFinish}>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Log In</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <Router>
      <Layout style={{ minHeight: '100vh', backgroundColor: '#001529' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} style={{ backgroundColor: '#001529' }}>
          <div className="dunno" /> {/* Ensure this div has some meaning or remove if not needed */}
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} />
        </Sider>
        <Layout style={{ backgroundColor: '#001529' }}>
          <Header style={{ padding: 0, backgroundColor: '#001529' }} />
          <Content style={{ margin: '0 16px', backgroundColor: '#001529', color: 'white' }}>
            {/* <Breadcrumb style={{ margin: '16px 0', color: 'white' }}>
              <Breadcrumb.Item>something here</Breadcrumb.Item>
              <Breadcrumb.Item>eventually</Breadcrumb.Item>
            </Breadcrumb> */}
            <Routes>
              <Route path="/server-messages" element={<ServerMessages />} />
              <Route path="/server-messages-twitch" element={<ServerMessagesTwitch />} />
              <Route path="/twitch-gen" element={<TwitchGen />} />
              {/* Add more <Route> elements for other paths */}
            </Routes>
            {/* <div
              style={{
                padding: 24,
                minHeight: 10,
                background: '#001529',
                color: 'white'
              }}
            >
              Placeholder
            </div> */}
          </Content>
          <Footer style={{ textAlign: 'center', backgroundColor: '#001529', color: 'white' }}>
            CS2inspects.com
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
