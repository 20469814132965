import React, { useState, useEffect, useRef } from 'react';

function Spinner() {
  const [spinning, setSpinning] = useState(false);
  const [itemBoxes, setItemBoxes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const listRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const url = 'https://test.api.cs2inspects.com/twitchgenboard/items?key=something123';
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ key: 'something123' })
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) throw new Error(`error ${response.status}`);
        const data = await response.json();

        const loadedItemBoxes = data.map(item => ({
          id: item._id,
          twitchUsername: item.twitch_username,
          genCode: item.itemdata.genCode,
          images: item.itemdata.comboParts.map(part => ({
            imageUrl: part.item_image,
            itemName: part.item_name,
            skinName: part.skin_name
          })).filter(part => part.imageUrl)
        }));

        // add filler
        setItemBoxes([...loadedItemBoxes, ...loadedItemBoxes, ...loadedItemBoxes]);
      } catch (error) {
        console.error('error', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (spinning && listRef.current && listRef.current.children.length) {
      let counter = 0;
      const maxCount = 200;
      let speed = 20;

      const spin = () => {
        if (counter < maxCount) {
          const firstChild = listRef.current.firstChild;
          listRef.current.appendChild(firstChild.cloneNode(true));
          listRef.current.removeChild(firstChild);
          counter++;
          if (counter >= 50) speed = 40;
          if (counter >= 100) speed = 80;
          if (counter >= 150) speed = 160;
          if (counter >= 180) speed = 320;
          timerRef.current = setTimeout(spin, speed);
        } else {
          setSpinning(false);
          setSelectedItem(listRef.current.firstChild.firstChild.src);
          clearTimeout(timerRef.current);
        }
      };

      timerRef.current = setTimeout(spin, speed);
    }

    return () => clearTimeout(timerRef.current);
  }, [spinning, itemBoxes]);

  const startSpinning = () => {
    setSpinning(true);
    setSelectedItem(null);
  };

  return (
    <div className="spinner-container">
      <div className="spinner-list-container">
        <div className="selection-pin"></div> {/* line */}
        <ul ref={listRef} className="spinner-list">
          {itemBoxes.map((box, index) => (
            <li key={`${box.id}-${index}`} className="spinner-item">
              <div className="card-header">
                <h3>{box.twitchUsername}</h3>
                <p>{box.genCode}</p>
              </div>
              {box.images.map((img, imgIndex) => (
                <img key={imgIndex} src={img.imageUrl} alt={`Item Box ${index} Image ${imgIndex}`} className="item-image" />
              ))}
            </li>
          ))}
        </ul>
      </div>
      <button onClick={startSpinning} className="spinner-button">Spin!</button>
      {selectedItem && <p>Selected Item: <img src={selectedItem} alt="Selected" style={{ width: '50px', height: '50px' }} /></p>}
    </div>
  );
}

export default Spinner;
